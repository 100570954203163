import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import HomePage from '../pages/HomePage'
import TermsPage from '../pages/TermsPage'
import EpisodePage from '../pages/EpisodePage'

const Routing = () => {
    const router=createBrowserRouter([
        {
            path:"/",element:<HomePage />
        },
        {
            path:"/terms",element:<TermsPage />
        },
        {
          path:"/:episode",element:<EpisodePage />
        }
    ])
  return (
    <RouterProvider router={router} />
  )
}

export default Routing