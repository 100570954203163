import React from 'react'
import classes from "./TermsPage.module.css";

const TermsPage = () => {
  return (
    <div className={classes.container}>
        <div className={classes.sub_container}>
            <h3>Terms & Conditions</h3>
        </div>
        <div className={classes.terms_container}>
            <p>

            </p>
            <p></p>
        </div>
    </div>
  )
}

export default TermsPage