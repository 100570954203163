import React, { useEffect, useState } from "react";
import classes from "./HomePage.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../api/api";
import ReactPlayer from "react-player";
import classes_2 from "./EpisodePage.module.css";
import dashjs from "dashjs";

const EpisodePage = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState(false);
  const { episode } = useParams();
  const navigate = useNavigate();
  const fetchDataFromBackend = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${baseURL}videos`);
      //   setData(res?.data);
      setFilteredData(() =>
        res?.data?.filter((dataItem) => {
          return dataItem?.id == episode;
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDataFromBackend();
  }, [episode]);
  const navigateHandler = () => {
    navigate("/");
  };
  const navigateToNextEpisode = () => {
    if (episode == "episode1") {
      navigate("/episode2");
    }
    if (episode == "episode2") {
      navigate("/episode3");
    }
  };
  const toggleMenu = () => {
    setMenu((prevValue) => !prevValue);
  };
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.header_title}>
          <h3 onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
            JOBURG MANSIONS
          </h3>
        </div>
        <div className={classes.menu_links}>
          <Link className={classes.link} to="/episode1">
            Episode 1
          </Link>
          <Link className={classes.link} to="/episode2">
            Episode 2
          </Link>
          <Link className={classes.link} to="/episode3">
            Episode 3
          </Link>
        </div>

        <div className={classes.menu_button}>
          {menu ? (
            <i
              className={`fa-solid fa-x ${classes.icon}`}
              onClick={() => toggleMenu()}
            ></i>
          ) : (
            <i
              className={`fa-solid fa-bars ${classes.icon}`}
              onClick={() => toggleMenu()}
            ></i>
          )}
        </div>
      </div>
      {/* MOBILE MENU OPEN */}
      <div
        className={`${classes.mobile_menu_container} ${
          menu && classes.menu_visible
        }`}
      >
        <div className={classes.mobile_menu_sub_container}>
          <div className={classes.header_title}>
            <h3 onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
              JOBURG MANSIONS
            </h3>
          </div>
          <Link className={classes.link} to="/episode1" onClick={()=>toggleMenu()}>
            Episode 1
          </Link>
          <Link className={classes.link} to="/episode2" onClick={()=>toggleMenu()}>
            Episode 2
          </Link>
          <Link className={classes.link} to="/episode3" onClick={()=>toggleMenu()}>
            Episode 3
          </Link>
        </div>
      </div>

      <div className={classes.sub_container}>
        <div className={classes.carousel_container}>
          <div className={classes.video_container_2}>
            {loading ? (
              <div className={classes.loading_container}>
                <span className={classes.loader}></span>
              </div>
            ) : (
              <ReactPlayer
                url={`${baseURL}${filteredData[0]?.video}`}
                controls
                className={classes_2.video}
                config={{
                  file: {
                    attributes: {
                      crossOrigin: "anonymous",
                    },
                  },
                  forceDASH: true, 
                  dashOptions: {
                    debug: {
                      logLevel: dashjs.Debug.LOG_LEVEL_INFO,
                    },
                  },
                }}
                // width="50rem"
                // height="auto"
                playing={true}
              />
            )}
            <div className={classes_2.episode_container}>
              <button
                type="button"
                className={classes_2.btn}
                onClick={() => navigateHandler()}
              >
                <i className={`fa-solid fa-arrow-left ${classes_2.icon}`}></i>Go
                Back
              </button>
              <h4 className={classes_2.episode}>
                {episode == "episode1"
                  ? "EPISODE 1"
                  : episode == "episode2"
                  ? "EPISODE 2"
                  : "EPISODE 3"}
              </h4>
              {episode != "episode3" && (
                <button
                  type="button"
                  className={classes_2.btn}
                  onClick={() => navigateToNextEpisode()}
                >
                  Next Episode
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EpisodePage;
