import React, { useRef, useEffect, useState } from "react";
import classes from "./HomePage.module.css";
import { Link, useNavigate } from "react-router-dom";
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";
import { Mousewheel, Pagination, EffectCards } from "swiper/modules";
import "swiper/css/effect-cards";
import ReactPlayer from "react-player";
import axios from "axios";
import { baseURL } from "../api/api";
SwiperCore.use([Mousewheel, EffectCards]);

const HomePage = () => {
  const swiperRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  // const [currentSlide, setCurrentSlide] = useState(0);

  const navigateHandler = (episode) => {
    navigate(episode);
  };

  useEffect(() => {
    if (!loading) {
      const swiperInstance = new SwiperCore(swiperRef.current, {
        direction: "horizontal",
        slidesPerView: 1,
        loop:true,
        spaceBetween: 0,
        effect: "cards",
        rotate:true,
        // on: {
        //   slideChange: () => {
        //     setCurrentSlide(swiperInstance?.activeIndex);
        //   },
        // },
      });

      return () => {
        swiperInstance.destroy(true, true);
      };
    }
  }, [loading]);

  const [data, setData] = useState([]);
  const fetchDataFromBackend = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${baseURL}videos`);
      console.log(res);
      setData(res?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDataFromBackend();
  }, []);

  const toggleMenu = () => {
    setMenu((prevValue) => !prevValue);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.header_title}>
          <h3 onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
            JOBURG MANSIONS
          </h3>
        </div>
        <div className={classes.menu_links}>
          <Link className={classes.link} to="/episode1">
            Episode 1
          </Link>
          <Link className={classes.link} to="/episode2">
            Episode 2
          </Link>
          <Link className={classes.link} to="/episode3">
            Episode 3
          </Link>
        </div>
        <div className={classes.menu_button}>
          {menu ? (
            <i
              className={`fa-solid fa-x ${classes.icon}`}
              onClick={() => toggleMenu()}
            ></i>
          ) : (
            <i
              className={`fa-solid fa-bars ${classes.icon}`}
              onClick={() => toggleMenu()}
            ></i>
          )}
        </div>
      </div>
      {/* MOBILE MENU OPEN */}
      <div
        className={`${classes.mobile_menu_container} ${
          menu && classes.menu_visible
        }`}
      >
        <div className={classes.mobile_menu_sub_container}>
          <div className={classes.header_title}>
            <h3 onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
              JOBURG MANSIONS
            </h3>
          </div>
          <Link className={classes.link} to="/episode1">
            Episode 1
          </Link>
          <Link className={classes.link} to="/episode2">
            Episode 2
          </Link>
          <Link className={classes.link} to="/episode3">
            Episode 3
          </Link>
        </div>
      </div>
      <div className={classes.sub_container}>
        <div className={classes.carousel_container}>
          {loading ? (
            <div className={classes.loading_container}>
              <span className={classes.loader}></span>
            </div>
          ) : (
            <div className="swiper-container" ref={swiperRef}>
              <div className="swiper-wrapper">
                {data?.length > 0 &&
                  !loading &&
                  data?.map((dataItem, i) => {
                    return (
                      <div
                        className="swiper-slide"
                        key={i}
                        onClick={() => i !== 0 && navigateHandler(dataItem?.id)}
                      >
                        <div
                          className={classes.video_container}
                        >
                          <img
                            src={`${baseURL}${data[2]?.image}`}
                            alt="random"
                            className={classes.img}
                          />
                          {i != 0 && (
                            <div className={classes.video_content}>
                              <h3>Episode {i}</h3>
                              <button
                                className={classes.btn_play}
                                type="button"
                              >
                                Play
                              </button>
                            </div>
                          )}
                          {i == 0 && (
                            <div className={classes.video_content_1}>
                              <h3>Featuring the best Joburg Mansions</h3>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
